import * as React from "react"
import { useState, useLayoutEffect, useRef } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/css/BlogDetail.scss"
import { Link } from "gatsby"
import { AiFillLinkedin } from "react-icons/ai"
import { FaTwitter } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"

import { BlogData } from "../components/blogDetails"
import blogEdgeqdebutsworlds from "../images/blog/Edgeqdebutsworldsfirst-img.png"

const Edgeqdebutsworldsfirst = () => {
  // Copy blog data to a state variable
  const [blogList, setBlogList] = React.useState([])

  React.useLayoutEffect(() => {
    setBlogList([...BlogData])
  }, [])

  const ref = useRef(null)

  console.log(ref?.current?.innerText, "ref")

  // Specify the id you want to find
  const targetId = 0

  // Use the find method to get the data for the item with the matching id
  const targetItem = BlogData.find(item => item.id === targetId)

  const wordsPerMinute = 120

  if (targetItem) {
    return <p>item</p>
  } else {
    return (
      <Layout>
        <Seo title="EdgeQ | EdgeQ Debuts World’s First Converged 4G+5G Base Station-on-a-Chip with Unprecedented Multi-Carrier Aggregation Capabilities" />

        <section className="News-detail blog-detail" ref={ref}>
          <Container>
            <Row>
              <div className="detail-head">
                <Link to="/blog" className="back-btn">
                  <FiArrowLeft /> Blog Page
                </Link>

                {/* <h2>{targetItem.blogTitle}</h2> */}
                <h2>EdgeQ Debuts World’s First Converged 4G+5G Base Station-on-a-Chip with Unprecedented Multi-Carrier Aggregation Capabilities</h2>
              </div>

              <div className="detail-para">

                <p className="text-left ">

                  by <a href="https://www.linkedin.com/in/adil-kidwai-1896366/" target="_blank"> Adil Kidwai </a> | VP of Product Management

                </p>

                {/* <p className="text-left ">

                  by <a href="https://www.linkedin.com/in/adil-kidwai-1896366/" target="_blank">Adil Kidwai</a> | VP of Product Management

                </p> */}

                <div className="date_time">
                  {/* <h5>{targetItem.blogDate}</h5> */}
                  <h5>14 Dec, 2023</h5>

                  <h6>
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 0C11.8869 0 14.1761 0.948211 15.864 2.63604C17.5518 4.32387 18.5 6.61305 18.5 9C18.5 11.3869 17.5518 13.6761 15.864 15.364C14.1761 17.0518 11.8869 18 9.5 18C7.11305 18 4.82387 17.0518 3.13604 15.364C1.44821 13.6761 0.5 11.3869 0.5 9C0.5 6.61305 1.44821 4.32387 3.13604 2.63604C4.82387 0.948211 7.11305 0 9.5 0ZM9.5 0.947368C7.36431 0.947368 5.31609 1.79577 3.80593 3.30593C2.29577 4.81609 1.44737 6.86431 1.44737 9C1.44737 11.1357 2.29577 13.1839 3.80593 14.6941C5.31609 16.2042 7.36431 17.0526 9.5 17.0526C10.5575 17.0526 11.6046 16.8443 12.5816 16.4397C13.5586 16.035 14.4463 15.4418 15.1941 14.6941C15.9418 13.9463 16.535 13.0586 16.9397 12.0816C17.3443 11.1046 17.5526 10.0575 17.5526 9C17.5526 6.86431 16.7042 4.81609 15.1941 3.30593C13.6839 1.79577 11.6357 0.947368 9.5 0.947368ZM9.02632 3.78947H9.97368V8.92421L14.4263 11.4916L13.9526 12.3158L9.02632 9.47368V3.78947Z"
                        fill="#6A6A6A"
                      />
                    </svg>
                    {Math.ceil(
                      ref?.current?.innerText?.split(" ").length /
                      wordsPerMinute
                    )}{" "}
                    min
                    {Math.ceil(
                      ref?.current?.innerText?.split(" ").length /
                      wordsPerMinute
                    ) !== 1
                      ? "s"
                      : ""}
                  </h6>
                </div>

                <div className="ajit-social">
                  <ul className="social-icons">
                    <li>
                      <a
                        aria-label="Save"
                        href="https://www.linkedin.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiFillLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        aria-label="Save"
                        href="https://twitter.com/edgeq_inc?lang=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        aria-label="Save"
                        href="https://www.youtube.com/@EdgeQInc/featured"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="blog_content_padd">
                  <div className="blog_img_one">
                    <img src={blogEdgeqdebutsworlds} alt="blogEdgeqdebutsworlds" />
                  </div>


                  {/* <p className="text-left">
                    {targetItem.blogDetails}
                  </p> */}

                  <p className="text-left">
                    This year has been a remarkable journey to launch into production our award-winning “5G+AI Base Station-on-a-Chip.”  Five years in the making with a team of maverick engineers, we enabled the industry with a new class of small cells and macro cells at breakthrough economics, performance, and power. 
                  </p>
                  <p className="text-left">
                    Today we have 3 industry-defining showcases that exemplify the transformative properties of EdgeQ’s solution. Each showcase is nothing short of phenomenal, reaffirming our pioneering spirit to be relevantly compelling.  
                  </p>

                  <h3>Raising the Bar:  World’s First 4 Carrier Aggregation gNB for Enterprise 5G Applications</h3>

                  <p className="text-left">
                    As the world contemplates the next trillion of devices, spectrum allocation will become a precious resource in wireless communications. The airwaves will become a heavily contended resource.  Today, managing and maximizing spectrum assets across multiple frequencies is an engineering challenge for operators. To provide improved performance with better user experience across denser connections, carrier operators will need to aggregate bandwidths across multiple frequencies to increase greater throughput.
                  </p>

                  <p className="text-left">
                    What EdgeQ is showcasing today is industry’s highest carrier aggregation for 5G!

                    <ul>
                      <li>
                        Single chip solution converging 4G, 5G, and AI at 1/2 cost, 1/3 the power, and 1/10 the space
                      </li>
                      <li>
                        3 to 4 Multi-carrier operation on a 4T4R small cells for enterprise private networks
                      </li>
                      <li>
                        Asymmetric carrier aggregation across multiple bandwidths – ex: 100+20, 20+10, …….
                      </li>
                      <li>
                        Asymmetric carrier aggregation between licensed bands and PAL/GAA spectrum assets
                      </li>
                    </ul>
                  </p>

                  <p className="text-left">
                    With up to 4 multi-carrier aggregation, EdgeQ enables operators to scale their network by improving spectral utilization by a staggering 2x!
                  </p>

                  <h3>The Present Future:  Unifying 4G and 5G onto a Single Silicon, Single PHY Layer</h3>

                  <p className="text-left">
                    As the market navigates from 4G to 5G, enterprises and operators are confronted with a fundamental challenge:  How do we deploy a wireless infrastructure that can effortlessly scale across all future upgrades and demands, without incurring the traditional capital and operating expense of a system redesign and rip-and-replace costs? 
                  </p>

                  <p className="text-left">
                  EdgeQ is the only company providing an integrated 4G+5G solution, complete with a multi-mode L1 Physical Layer, an interoperable L2/L3 software stack, all on a single chip. Because making a wholesale shift from 4G to 5G is costly, customers can leverage a single converged solution, upgrade over-the-air at compelling unit economics of 1/2 the cost and 1/3 the power. 
                  </p>

                  <p className="text-left">
                  What EdgeQ is showcasing today is an unprecedented industry first! 

                    <ul>
                      <li>
                        A fanless system with a single silicon running simultaneously 4G and 5G at {"<15"} Watt of SoC power
                      </li>
                      <li>
                        An out-of-box, production-grade ready L1 layer converging 4G+5G.  Fully programmable
                      </li>
                      <li>
                        An interoperable, production-grade L2/L3 stack
                      </li>
                      <li>
                        A single design instantiation capable of scaling from 2x2 to 4x4 to 8x8 antenna configurations
                      </li>
                      <li>
                        Elastic scaling from 64 to 256 active users
                      </li>
                    </ul>
                  </p>

                  <p className="text-left">
                    Our achievement abstracts all the friction points associated with 4G to 5G migration, thereby allowing customers to bridge and manage to future standards, performance needs, and user requirements without the need for truck-rolls.
                  </p>


                  <h3>One More Thing   …Industry’s First Voice-Over-NR on a Private Network with Converged 4G+5G SoC</h3>

                  <p className="text-left">
                  EdgeQ was founded on the spirit of meaningful convergence. Our unique architecture converts connectivity into a massive compute problem, thereby simultaneously supporting both 5G and AI inference.  Our unified L1 software converges both 4G and 5G.  As data and voice are inextricably linked in modern day communications, we wanted to converge these two in an efficient and logical manner.
                  </p>

                  <p className="text-left">
                  The encore to our announcement today features industry’s very first voice-over-NR.  Our showcase modernizes the future of 5G networks to deliver not only high-quality data but also enable our customers to monetize voice services as part of a broader and richer 5G experience.
                  </p>



                </div>
              </div>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
}
export default Edgeqdebutsworldsfirst;
